.experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.experience__box {
    /* background-color: var(--color-bg-variant); */
    /* padding: 2.5rem 2.5rem; */
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.experience__content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

/* .experience__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
} */

.experience__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.experience__item {
    background-color: var(--color-bg-variant);
    padding: 1rem;
    border-radius: 2rem;
    display: grid;
    grid-template-columns: 20% 80%;
    transition: var(--transition);
    cursor: pointer;
}

.experience__work-item {
    background-color: var(--color-bg-variant);
    padding: 1rem;
    padding-left: 2rem;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    transition: var(--transition);
    cursor: pointer;
}

.experience__item:hover {
    background: var(--color-primary);
    color: var(--color-bg);
}

.experience__work-item:hover {
    background: var(--color-primary);
    color: var(--color-bg);
}

.experience__image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.experience__image img {
    width: 6rem;
}

.experience__content-bullet {
    list-style: none;
    padding-left: 1.5rem;
    padding-right: 2rem;
    text-align: justify;
}
  
.experience__content-bullet-item::before {
content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
font-weight: bold; /* If you want it to be bold */
display: inline-block; /* Needed to add space between the bullet and the text */
width: 1em; /* Also needed for space (tweak if needed) */
margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.divider {
    width: 100%;
    height: 0.25rem; /* Adjust thickness of the line */
    background-color: var(--color-bg); /* Adjust color of the line */ 
    margin: 1rem 0;
    border-radius: 0.4rem;
}

/* ===== MEDIA QUERIES (MEDIUM DEVICES) ===== */

@media screen and (max-width: 1024px) {
    .experience__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .experience__content-bullet {
        padding-right: 1rem;
    }
  }

/* ===== MEDIA QUERIES (SMALL DEVICES) ===== */

@media screen and (max-width: 600px) {
    .experience__item {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        text-align: center;
    }

    .experience__content-bullet {
        padding-right: 1rem;
    }

    .experience__image {
        padding-top: 1rem;
        padding-left: 0;
        align-self: center;
        width: 3rem;
    }
  }