.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background-color: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    background-color: var(--color-primary);
    color: var(--color-bg);
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden; 
}

.portfolio__item h3 {
    margin: 1.2rem 0 0.5rem;
} 

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
}

.portfolio__item-collapse {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.portfolio__item a {
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.portfolio__item a:hover {
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);
}

.portfolio__item-buttons {
    display: flex;
    gap: 1rem;
}

.portfolio__item-collapse p {
    text-align: justify;
}

/* ===== MEDIA QUERIES (MEDIUM DEVICES) ===== */

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
  }

/* ===== MEDIA QUERIES (SMALL DEVICES) ===== */

@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
  }