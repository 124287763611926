header {
    /* height: 100vh; */
    padding-top: 5rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 18rem;
    /* position: absolute; */
    /* left: calc(50% - 11rem); */
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 3rem 0rem 1.5rem 0rem;
}

/* ===== MEDIA QUERIES (MEDIUM DEVICES) ===== */

@media screen and (max-width: 1024px) {
  }

/* ===== MEDIA QUERIES (SMALL DEVICES) ===== */

@media screen and (max-width: 600px) {
    .me {
        width: 14rem;
    }
  }